
export default {
  data() {
    return {
      // time: 10000,
      time: new Date('Sun Aug 01 2021 11:00:00 GMT+0800') - Date.now(),
    }
  },
  methods: {
    finish() {
      this.$router.replace('/login')
    }
  }
}
